<!--
 * @Autor: oops.liu
今日上新
-->
<template>
  <div class="exclusive-box">
    <div class="box">
      <!-- 选择区 -->
      <navbarbox bg="#fff">
        <div class="search-box">
          <van-nav-bar
            title="标题"
            :border="false"
            left-arrow
            @click-left="onClickLeft"
          >
            <template #title>
              <van-search
                v-model="value"
                placeholder="搜索商品"
                shape="round"
                readonly
                @click.native="search"
              />
            </template>
          </van-nav-bar>
        </div>
      </navbarbox>
      <div class="check-domain">
        <div class="order-list">
          <div class="order-left">
            <select-sort
              :list="check_list"
              @change="orderChange"
              v-model="cat"
            />
          </div>
          <div class="order-right" @click="checkLineHandle">
            <van-icon v-if="line" :name="card" />
            <van-icon v-else-if="!line" :name="list" />
          </div>
        </div>
      </div>
    </div>
    <div class="content-box">
      <scroller
        ref="my_scroll"
        :noDataText="noDataText"
        :on-infinite="infinite"
        :on-refresh="refresh"
      >
        <div class="exclusive">
          <!-- 商品区 -->
          <div class="goods-list flex_sb">
            <template v-if="!line">
              <goods-item
                v-for="item in listData"
                :key="item.id + 'b'"
                :info="item"
                height="172px"
                @jump="handleGoods"
              />
            </template>
            <template v-else-if="line">
              <goods-line-item
                v-for="item in listData"
                :key="item.id"
                :info="item"
                @jump="handleGoods"
              />
            </template>
          </div>
        </div>
      </scroller>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import scrollMethods from "@/mixin/scroll.js";
import handleGoods from "@/mixin/handleGoods.js";
import selectSort from "./components/selectSort.vue";
import goodsItem from "./components/goodsItem.vue";
import goodsLineItem from "./components/goodsLineItem.vue";
import navbarbox from "@/components/common/navbarbox.vue";
export default {
  components: { navbarbox, selectSort, goodsItem, goodsLineItem },
  mixins: [scrollMethods, handleGoods],
  props: {},
  data() {
    return {
      line: false,
      cat: 0,
      card: require("@/assets/img/card_icon.png"),
      list: require("@/assets/img/list_icon.png"),
      isHot: true,
      sortColumn: "",
      isAsc: false,
      asc: true,
    };
  },
  computed: {
    ...mapState({
      goods: (state) => state.app.goods,
    }),

    value() {
      return this.$route.query.val;
    },
    check_list() {
      return [
        {
          id: 0,
          title: "综合",
          is_order: false,
          name: "create_time",
        },
        {
          id: 1,
          title: "销量",
          is_order: false,
          name: "sale_num",
        },

        {
          id: 2,
          title: "价格",
          is_order: true,
          name: "price_down",
        },
      ];
    },
  },
  methods: {
    ...mapActions({ load: "seller/searchGoodsCategory" }),
    async loadInfo(isRefresh = false) {
      const params = {
        shopId: this.$route.params.shopId,
        current: this.pages,
        name: this.value,
        sortColumn: this.sortColumn,
        asc: this.asc,
      };

      if (!this.sortColumn) {
        delete params.sortColumn;
      }
      if (!this.isAsc) {
        delete params.asc;
      }
      const data = await this.load({ ...params });
      this.dataLength = data.records.length;
      if (isRefresh) {
        this.listData = data.records;
      } else {
        this.listData = this.listData.concat(data.records);
      }
      return data;
    },
    search() {
      this.$router.replace("/search");
    },
    onClickLeft() {
      this.$router.replace("/search");
    },

    orderChange(item) {
      console.log(item);
      this.cat = item.active;
      this.pages = 1;
      this.sortColumn = item.cat;
      this.listData = [];
      this.isAsc = item.cat === "price_down";
      this.asc = item.asc;

      this.loadInfo();
    },

    checkLineHandle() {
      this.line = !this.line;
    },
  },
  created() {
    this.loadInfo();
  },
  filters: {
    lengthFilter(string) {
      let str;
      if (string.length > 5) {
        str = string.substring(0, 4) + "...";
      } else {
        str = string;
      }
      return str;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
