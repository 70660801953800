<template>
  <div class="goods-item" v-if="info" @click="handle(info)">
    <div class="cover">
      <img @load="load" :src="info.picUrls[0]" alt="" />
    </div>
    <div class="info">
      <p class="goods-name line2">{{ info.name }}</p>
      <p class="hint">满2件打8折</p>

      <div class="price-box">
        <div class="operated flex_sb">
          <p class="price-info"><span>￥</span>{{ info.priceDown }}</p>
        </div>
        <div class="operated flex_sb">
          <p class="old-price">500+条评价，98%好评</p>
          <van-button class="btn">购买</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    handle(info) {
      this.$emit("jump", info);
    },
    load() {
      this.$emit("imgload");
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.goods-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px;
  &:last-of-type {
    margin-bottom: 5px;
  }
  .cover {
    width: 110px;
    height: 110px;
    border-radius: 7px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
  .info {
    flex: 1;
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    .goods-name {
      align-items: flex-start;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .hint {
      font-size: 12px;
      color: #ff0000;
    }

    .price-box {
      width: 100%;
      display: flex;
      flex: inherit;
      flex-wrap: wrap;
      font-size: 14px;
      align-items: flex-end;
      .operated {
        width: 100%;
        .price-info {
          font-size: 16px;
          color: #ff0000;
          span {
            font-size: 12px;
          }
        }
        .btn {
          width: 53px;
          height: 22px;
          background: linear-gradient(90deg, #ff1551 0%, #ff4007 100%);
          border-radius: 5px;
          color: #fff;
          font-size: 12px;
          border: 0px;
        }
        .old-price {
          font-size: 12px;
          color: #ccc;
          transform: scale(0.95);
          transform-origin: 0;
        }
        .progress-box {
          font-size: 12px;
          color: #9c9c9c;
          display: flex;
          align-items: center;
          .hint {
            display: inline-block;
            transform: scale(0.95);
            transform-origin: 0;
            color: #9c9c9c;
          }
          ::v-deep .van-progress {
            display: inline-block;
            width: 66px;
          }
        }
      }
    }
  }
}
</style>
