<template>
  <div class="cate_title">
    <div
      class="title_item"
      v-for="item in list"
      :key="item.id"
      :class="{ active: active === item.id }"
      @click="jump(item)"
    >
      <p
        :class="{
          order: item.is_order,
          order_asc: order === 1,
          order_desc: order === 0,
        }"
      >
        {{ item.title }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "categoryCheck",
  components: {},
  model: {
    prop: "active", //要存在于proops
    event: "change", //当组件的值发生改变时要emit的事件名
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Number,
    },
  },
  data() {
    return {
      order: 0,
    };
  },
  computed: {},
  watch: {},
  methods: {
    jump(item) {
      this.order === 1 ? (this.order = 0) : (this.order = 1);
      this.$emit("change", {
        cat: item.name,
        asc: this.order === 1,
        active: item.id,
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped lang="scss">
.cate_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  font-size: 14px;
  .title_item {
    flex: 1;
    text-align: center;
    padding: 10px 0;
    p {
      display: inline-block;
      position: relative;
      padding: 2px 0;
    }
    .order {
      &::before {
        content: "";
        position: absolute;
        top: 5px;
        right: -5px;
        width: 0;
        height: 0;
        border: 3px solid #000;
        transform: translate(100%);
        border-color: transparent transparent #000 transparent;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 3px;
        right: -5px;
        width: 0;
        height: 0;
        border: 3px solid #000;
        transform: translate(100%);
        border-color: #000 transparent transparent transparent;
      }
    }
  }
  .active {
    color: #f02b2b;
    .order_asc {
      &::before {
        border-color: transparent transparent #f02b2b transparent;
      }
    }
    .order_desc {
      &::after {
        border-color: #f02b2b transparent transparent transparent;
      }
    }
  }
}
</style>
